import * as React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import InternalLink from '../components/soul/navigation/InternalLink';
import TwoColumnsTwoThirdsOneThird from '../layouts/TwoColumnsTwoThirdsOneThird';
import speakerDetailsContent from '../content/speakers/speaker-details-content.json';
import { SpeakerFrontmatter } from '../components/speaker/SpeakersRow';
import SEO from '../components/SEO/SEO';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface SpeakerDetailsPageProps {
    data: QueryResult
}

interface QueryResult {
    markdownRemark: {
        html: string
        frontmatter: SpeakerWithSpeeches
    }
}

interface SpeakerWithSpeeches extends SpeakerFrontmatter {
    speeches: Speech[]
}

interface Speech {
    id: string
    relativePath: string
    childMarkdownRemark: {
        frontmatter: {
            title: string,
            originalStartDate: string
            formattedStartDate: string
            formattedEndDate: string
        }
    }
}

const SpeakerDetailsPage: React.FC<SpeakerDetailsPageProps> = ({data}) => {
    const avatar = data.markdownRemark.frontmatter.avatar.childImageSharp.fluid;
    const fullName = `${data.markdownRemark.frontmatter.firstName} ${data.markdownRemark.frontmatter.lastName}`;
    const jobTitle = data.markdownRemark.frontmatter.jobTitle;
    const organization = data.markdownRemark.frontmatter.organization;
    const speeches = (speaker: QueryResult) => speaker.markdownRemark.frontmatter.speeches
        ?.sort((a, b) =>
            new Date(a.childMarkdownRemark.frontmatter.originalStartDate).getTime() - new Date(b.childMarkdownRemark.frontmatter.originalStartDate).getTime()
        );
    const path = (speech: Speech) => `/${speech.relativePath.replace('.md', '')}/`;
    const schema = {
        '@context': 'https://schema.org/',
        '@type': 'Person',
        'name': fullName,
        'image': avatar.src,
        'jobTitle': jobTitle,
        'worksFor': {
            '@type': 'Organization',
            'name': organization
        }
    };

    const seoTitle = `ESTECO UM20 | Listen to ${fullName} from ${organization}`;
    const seoDescription = `Listen to ${fullName} and learn how ${organization} applied ESTECO software solutions to drive the design process.`;

    return (
            <TwoColumnsTwoThirdsOneThird>
                <React.Fragment>
                    <SEO
                        description={seoDescription}
                        title={seoTitle}
                        schemaMarkup={schema}/>
                    <div className="soul-space-stack-top-3-xl s-avatar s-avatar--xl s-avatar--primary">
                        <Img className="s-avatar__image ew-um-avatar" fluid={avatar} alt={fullName}/>
                    </div>

                    <h1 className="soul-space-stack-top-xl soul-font-size-xl h-text-bold">{fullName}</h1>
                    <p className="soul-font-size-l">{jobTitle}</p>
                    <p className="soul-space-stack-bottom-xl soul-font-size-l h-text-bold">{organization}</p>
                    <div className="soul-content  soul-content--m  soul-space-stack-bottom-l" dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}/>
                </React.Fragment>

                <React.Fragment>
                    <h2 className="soul-space-stack-top-3-xl soul-font-size-xl h-text-bold">{speakerDetailsContent.talks.title}</h2>

                    {speeches(data)?.map(speech =>
                        <div key={speech.id} className="soul-space-stack-top-l">
                            {speakerDetailsContent.talks.showDateAndTime ?
                                <div className="soul-space-stack-bottom-s">
                                    <time dateTime={speech.childMarkdownRemark.frontmatter.formattedStartDate}>
                                        {speech.childMarkdownRemark.frontmatter.formattedStartDate} - {speech.childMarkdownRemark.frontmatter.formattedEndDate} CEST
                                    </time>
                                </div> : ''
                            }

                            <InternalLink to={path(speech)}>
                                <button className="m-button-card">
                                <span className="h-tx-color--brand-base  soul-font-size-m  h-text-bold">
                                    {speech.childMarkdownRemark.frontmatter.title}
                                </span>
                                </button>
                            </InternalLink>
                        </div>
                    )}
                </React.Fragment>
            </TwoColumnsTwoThirdsOneThird>
    );
};

export const query = graphql`
    query RetrieveSpeaker($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                firstName
                lastName
                jobTitle
                avatar {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                organization
                speeches {
                    id
                    relativePath
                    childMarkdownRemark {
                        frontmatter {
                            title
                            originalStartDate: startDateTime
                            formattedStartDate: startDateTime(formatString: "DD MMMM, HH:mm")
                            formattedEndDate: endDateTime(formatString: "HH:mm")
                        }
                    }
                }
            }
        }
    }
`;

export default SpeakerDetailsPage;
