import * as React from 'react';
import Grid from '../components/soul/layout/grid/Grid';
import {GridDensity} from '../components/soul/layout/grid/GridDensity';
import GridItem from '../components/soul/layout/grid/GridItem';
import {GridItemSize} from '../components/soul/layout/grid/GridItemSize';
import SingleColumnLayout from './singleColumnLayout/SingleColumnLayout';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface LayoutProps {
    children: React.ReactNode[]
}

const TwoColumnsTwoThirdsOneThird: React.FC<LayoutProps> = ({children}) => (
    <SingleColumnLayout>
        <div className="centered-column">
            <Grid density={GridDensity.WIDE}>
                <GridItem size={GridItemSize.TWO_THIRDS} additionalClasses="l-palm--one-whole">
                    {children[0]}
                </GridItem>
                <GridItem size={GridItemSize.ONE_THIRD} additionalClasses="l-palm--one-whole">
                    {children[1]}
                </GridItem>
            </Grid>
        </div>
    </SingleColumnLayout>
);

export default TwoColumnsTwoThirdsOneThird;
